import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Link, useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';
import { imageUrls } from '~/assets';
import Button from '~/components/atoms/Button';
import CircularProgress from '~/components/atoms/CircularProgress';
import { RecruitmentProgress } from '~/components/organisms/Recruitment/RecruitmentProgress';
import { lazyloadBackground } from '~/utils/performance';

export const RecruitmentCampaignBanner = ({
    recruitedUsersNumber,
    fetcher,
    claimRewardRoute,
}: {
    recruitedUsersNumber: number;
    fetcher: ReturnType<typeof useFetcher>;
    claimRewardRoute: string;
}): JSX.Element => {
    const theme = useTheme();
    const recruitedUsersNeeded = 3;

    const [recruitmentCampaignBgImage, setRecruitmentCampaignBgImage] = useState('');

    useEffect(() => {
        lazyloadBackground({
            elementId: 'recruitment-campaign-banner-bg-image',
            intersectionHandler: () => {
                setRecruitmentCampaignBgImage(imageUrls.recruitmentCampaign);
            },
            once: true,
        });
    }, []);

    return (
        <Stack
            sx={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'stretch',
                backgroundColor: theme.palette.background.dark,
                borderRadius: theme.radius.allLarge,
                mb: { xs: 12, sm: 18 },
            }}
        >
            <Stack
                sx={{
                    px: { xs: 8, sm: 12, xl: 15 },
                    py: { xs: 8, sm: 12, xl: 15 },
                    justifyContent: 'center',
                    alignItems: { xs: 'center', sm: 'flex-start' },
                    width: { xs: '100%', sm: 8 / 10, md: 6 / 10, lg: 5 / 10 },
                }}
            >
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        textAlign: { xs: 'center', sm: 'initial' },
                        pb: { xs: 3, lg: 4 },
                    }}
                >
                    {recruitedUsersNumber >= recruitedUsersNeeded && <>Gratulerer!</>}
                    {recruitedUsersNeeded - recruitedUsersNumber === 1 && <>Nesten i mål!</>}
                    {recruitedUsersNumber < recruitedUsersNeeded - 1 && (
                        <>
                            Verv tre venner og få Allvit+
                            <Box component="br" sx={{ display: { xs: 'none', xl: 'block' } }} /> GRATIS I 3 MÅNEDER
                        </>
                    )}
                </Typography>
                <Stack
                    sx={{
                        flexGrow: 2,
                        justifyContent: 'center',
                    }}
                >
                    {recruitedUsersNumber > 0 ? (
                        <RecruitmentProgress
                            recruitedUsers={recruitedUsersNumber}
                            fetcher={fetcher}
                            claimRewardRoute={claimRewardRoute}
                            sx={{
                                pb: 4,
                            }}
                        />
                    ) : (
                        <Typography
                            variant="p1"
                            sx={{ pb: { xs: 8, sm: 10 }, textAlign: { xs: 'center', sm: 'initial' } }}
                        >
                            I tillegg til at du får tre måneder gratis abonnement på Allvit+, vil hver venn du verver få{' '}
                            <b>25% rabatt</b> på de tre første månedene av abonnementet.
                        </Typography>
                    )}
                </Stack>
                <Stack sx={{ flexGrow: 1, justifyContent: 'flex-start', alignItems: 'flex-end', flexDirection: 'row' }}>
                    {recruitedUsersNumber >= recruitedUsersNeeded ? (
                        <fetcher.Form method="post" action={claimRewardRoute}>
                            <input type="hidden" name="action" value="claimMagicLinkReward" />
                            <Button
                                type="submit"
                                disabled={fetcher.state !== 'idle'}
                                size="large"
                                variant="primary"
                                startIcon={
                                    fetcher.state !== 'idle' && (
                                        <CircularProgress sx={{ color: theme.palette.common.white }} size={18} />
                                    )
                                }
                                sx={{ mr: 4 }}
                            >
                                {fetcher.state !== 'idle' ? 'Kreve belønning...' : 'Krev belønning'}
                            </Button>
                        </fetcher.Form>
                    ) : (
                        <Button
                            variant={recruitedUsersNumber >= recruitedUsersNeeded ? 'secondary' : 'primary'}
                            component={Link}
                            size="large"
                            to="/profil/verve"
                            sx={{ width: 'fit-content' }}
                        >
                            {recruitedUsersNumber > 0 ? 'Sjekk status og send invitasjoner' : 'Start nå!'}
                        </Button>
                    )}
                </Stack>
            </Stack>
            <Stack
                sx={{
                    p: { xs: 0, md: 6 },
                    position: 'relative',
                    width: { xs: 0, sm: 2 / 10, md: 4 / 10, lg: 5 / 10 },
                    display: { xs: 'none', sm: 'flex' },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '3rem',
                        right: '3rem',
                        bottom: '3rem',
                        left: '3rem',
                        transform: 'rotateZ(2deg)',
                        border: `3px solid ${theme.palette.primary.main}`,
                        borderRadius: theme.radius.allLarge,
                        display: { xs: 'none', md: 'block' },
                    }}
                />
                <Box
                    id="recruitment-campaign-banner-bg-image"
                    sx={{
                        backgroundImage: `url(${recruitmentCampaignBgImage})`,
                        borderRadius: theme.radius.allLarge,
                        height: '100%',
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        display: { xs: 'none', md: 'block' },
                    }}
                />
                <Stack
                    sx={{
                        m: 6,
                        position: 'absolute',
                        top: 'auto',
                        right: 'auto',
                        bottom: '-15px',
                        left: '-40px',
                        alignItems: 'center',
                        width: 'fit-content',
                        [theme.breakpoints.down('md')]: {
                            top: '0px',
                            right: '0px',
                            bottom: 'auto',
                            left: 'auto',
                            transform: 'rotate(15deg)',
                        },
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#FAC965',
                            borderRadius: '50%',
                            width: '110px',
                            height: '110px',
                        }}
                    >
                        <Typography
                            variant="p2"
                            sx={{
                                fontWeight: 700,
                                textAlign: 'center',
                                lineHeight: 1.2,
                                color: theme.palette.primary.contrastText,
                            }}
                        >
                            Verv fler,
                            <br /> spar mer!
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
