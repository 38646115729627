import { Box, BoxProps, alpha, useTheme } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import Testimonial from '~/components/molecules/Testimonial';

import { TestimonialTypes } from '~/types/app/testimonial';
import { mergeSx } from '~/utils/mui';

interface TestimonialCarouselProps extends BoxProps {
    id: string;
    testimonials: Array<TestimonialTypes>;
    maxWidth?: 'xl' | 'xxl';
}

const TestimonialCarousel = ({ id, testimonials, maxWidth, sx, ...props }: TestimonialCarouselProps) => {
    const theme = useTheme();

    if (!testimonials || testimonials?.length < 1) {
        return null;
    }

    const perPageXl = maxWidth && maxWidth === 'xxl' ? [3, 3, 2, 2, 1] : [3, 2, 2, 2, 1];

    return (
        <>
            <Box
                sx={mergeSx(
                    {
                        width: 1,
                        '.splide__track': { py: '3px' },
                        '.splide__arrow': {
                            opacity: '1',
                            borderRadius: theme.radius.all,
                            backgroundColor: alpha(theme.palette.cta.main, 0.07),
                            svg: { fill: theme.palette.cta.main, opacity: 1 },
                            width: 40,
                            height: 40,
                            ':disabled': {
                                opacity: 0,
                            },
                            '&:focus-visible': {
                                boxShadow: theme.shadows[24],
                                outline: 'none !important',
                            },
                        },
                        '.splide__arrow--prev': {
                            transform: 'translateX(-100%)',
                            [theme.breakpoints.up('md')]: {
                                transform: 'translateX(-150%)',
                            },
                        },
                        '.splide__arrow--prev:hover,.splide__arrow--next:hover': {
                            backgroundColor: alpha(theme.palette.cta.main, 0.15),
                        },
                        '.splide__arrow--next': {
                            transform: 'translateX(100%)',
                            [theme.breakpoints.up('md')]: {
                                transform: 'translateX(150%)',
                            },
                        },
                    },
                    sx,
                )}
                {...props}
            >
                <Splide
                    id={`splide-list-${id}`}
                    options={{
                        type: 'rewind',
                        gap: theme.spacing(6),
                        pagination: false,
                        lazyLoad: true,
                        mediaQuery: 'min',
                        padding: { left: 0, right: 0 },
                        perPage: perPageXl[0],
                        breakpoints: {
                            [theme.breakpoints.values.xs]: {
                                perPage: perPageXl[4],
                            },
                            [theme.breakpoints.values.sm]: {
                                perPage: perPageXl[3],
                            },
                            [theme.breakpoints.values.md]: {
                                perPage: perPageXl[2],
                            },
                            [theme.breakpoints.values.lg]: {
                                perPage: perPageXl[1],
                            },
                            [theme.breakpoints.values.xl]: {
                                perPage: perPageXl[0],
                            },
                        },
                    }}
                >
                    {testimonials.map((testimonial) => {
                        if (!testimonial) return null;
                        return (
                            // transform on SplideSlide is a hack to make the rotate animation of the testimonial tile image work on safari
                            <SplideSlide
                                key={`splide-item-${testimonial.key}`}
                                style={{ transform: `translate(-1px)` }}
                            >
                                <Testimonial key={testimonial.key} testimonial={testimonial} />
                            </SplideSlide>
                        );
                    })}
                </Splide>
            </Box>
        </>
    );
};

export default TestimonialCarousel;
