import { Box, ButtonBase, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';

import { ArrowRight as IconArrowRight } from 'react-feather';
import { TopBannerButtonProps } from './types';

const TopBannerButton = ({ label, to }: TopBannerButtonProps) => {
    const theme = useTheme();
    const [appearance, setAppearance] = useState({
        backgroundColor: theme.palette.background.dark,
        hoverColor: theme.palette.background.dark,
        color: theme.palette.background.dark,
        parameter: '',
    });

    useEffect(() => {
        if (Math.random() < 0.5) {
            setAppearance({
                backgroundColor: theme.palette.cta.main,
                hoverColor: theme.palette.cta.dark,
                color: theme.palette.common.white,
                parameter: 'p',
            });
        } else {
            setAppearance({
                backgroundColor: theme.palette.primary.main,
                hoverColor: theme.palette.primary.dark,
                color: theme.palette.text.primary,
                parameter: 'y',
            });
        }
    }, []);

    return (
        <ButtonBase
            component="a"
            href={`${to}?c=${appearance.parameter}`}
            sx={{
                height: { xs: 'auto', lg: '50px' },
                backgroundColor: appearance.backgroundColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
                color: theme.palette.secondary.main,
                transition: 'background-color 0.5s',
                ':hover': {
                    backgroundColor: appearance.hoverColor,
                },
            }}
        >
            <Box
                component="span"
                sx={{
                    mx: 3,
                    my: { xs: 3, lg: 0 },
                    fontWeight: '600',
                    textAlign: 'center',
                    display: 'inline-block',
                    color: appearance.color,
                    transition: 'color 0.5s',
                }}
            >
                {label}
                <IconArrowRight
                    size={18}
                    color={appearance.color}
                    style={{ marginLeft: '10px', marginBottom: '-3px' }}
                />
            </Box>
        </ButtonBase>
    );
};

export default TopBannerButton;
