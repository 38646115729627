// front page

import { getBooksInSubs, getMostSoldBooks } from '~/utils/apis/book';
import { getInventoryV2, getUsersSubscriptions } from '~/utils/apis/inventory';
import { createInventoryQuery } from '~/utils/inventory';

import BookTypes from '~/types/app/book';
import type { SubscriptionPlanProfileTypes } from '~/types/app/inventory';
import { TestimonialVariant, type TestimonialTypes } from '~/types/app/testimonial';
import { fetchDashboard } from '~/usecases/dashboard';

import type { LoaderFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import splideStyles from '@splidejs/splide/dist/css/themes/splide-default.min.css?url';
import { fetchFrontpage } from '~/usecases/frontpage';
import { resolveChunks, resolveEdges, resolveField, resolveFieldFromChunk } from '~/utils/cms';

import DashboardPage from '~/components/pages/Dashboard';
import FrontPage from '~/components/pages/Front';
import globalConfig from '~/configs/global';
import { CrystallizeApiTestimonial } from '~/types/app/crystallize';
import { RecruitedUsersType } from '~/types/magicLink';
import { getBookList } from '~/utils/apis/book';
import { getRecruitedUsers } from '~/utils/apis/magicLink';
import { getAdditionalUserData, getUser } from '~/utils/apis/user';
import { isUserAuthenticated } from '~/utils/auth';
import { useCacheForFunction } from '~/utils/cache.server';
import { useCurrentUser } from '~/utils/user';

type TopicsExtendedTypes = {
    key: string;
    label: string;
    routeId: string;
    status: 'init' | 'empty' | 'gotIsbns' | 'gotBooks';
    isbns: Array<string>;
    books: Array<BookTypes>;
};

export function links() {
    return [{ rel: 'stylesheet', href: splideStyles }];
}

export const loader: LoaderFunction = async ({ request }) => {
    const signedIn = isUserAuthenticated(request);
    const { success, data: user } = await getUser(request);

    // Data retrieval for dashboard for signed in users
    if (signedIn && success) {
        //
        // Interests
        //

        const additionalUserDataPromise = getAdditionalUserData(request);

        //
        // Subscription info
        //

        const userSubsPromise = getUsersSubscriptions(request);

        //
        // Subscription info
        //

        const recruitedUsersPromise = getRecruitedUsers(request);

        //
        // Loading content from CMS
        //

        const cmsData = await fetchDashboard();

        const lineBanner = {
            label: resolveField(cmsData, ['frontpage', 'lineBanner'], 'label', 'text'),
            url: resolveField(cmsData, ['frontpage', 'lineBanner'], 'url', 'text'),
        };
        const blogPosts = resolveEdges(cmsData, 'lastBlogposts')
            ?.filter((post: any) => !post?.node?.html)
            .slice(0, 2);
        const bookChunks = resolveChunks(cmsData, ['frontpage', 'books']);

        //
        // Books for desked book rows
        //

        let plans: SubscriptionPlanProfileTypes[] = [];
        let booksInSubs = [];

        const booksPromises = bookChunks.map((chunk: any) => {
            const isbns = resolveFieldFromChunk(chunk, 'isbns', 'text');
            const isbnsSplit = isbns.replace(/\s/g, '').split(',');

            return getBookList(request, isbnsSplit, false);
        });

        //
        // Books for automatic book rows
        //

        const booksInSubsPromise = getBooksInSubs(request, false);

        //
        // Loading inventory
        //

        const lastReadQuery = createInventoryQuery(true, '', 0, '', 'LAST_READ', 10);
        const lastAcquiredQuery = createInventoryQuery(true, '', 0, '', 'ACQUIRED', 6);

        const lastReadInventoryPromise = getInventoryV2(request, lastReadQuery);
        const lastAcquiredInventoryPromise = getInventoryV2(request, lastAcquiredQuery);

        //
        // Resolve promises
        //

        const [
            additionalUserData,
            lastReadInventory,
            lastAcquiredInventory,
            booksInSubsResult,
            userSubs,
            recruitedUsers,
            ...bookLists
        ] = await Promise.allSettled([
            additionalUserDataPromise,
            lastReadInventoryPromise,
            lastAcquiredInventoryPromise,
            booksInSubsPromise,
            userSubsPromise,
            recruitedUsersPromise,
            ...booksPromises,
        ]);

        //
        // Resolve interests
        //

        let topics: Array<TopicsExtendedTypes> = [];

        if (
            additionalUserData.status === 'fulfilled' &&
            (additionalUserData.value.status === 200 || additionalUserData.value.status === 201)
        ) {
            // Array of interest titles
            const topicsOfInterestList = additionalUserData.value.data?.topicsOfInterest;

            if (topicsOfInterestList && topicsOfInterestList.length > 0) {
                topics = globalConfig.topics
                    .filter((t) => topicsOfInterestList.includes(t.label))
                    .map((t) => ({
                        key: t.key,
                        label: t.label,
                        routeId: t.routeId as string,
                        status: 'init',
                        isbns: [],
                        books: [],
                    }));

                // Array of promises for popular books on each interest
                const interestsOnPopularPromises = topics.map((i) => i.label).map((i) => getMostSoldBooks(request, i));

                // Array of resolved promises for popular books on each interest
                const topicsOfInterest = await Promise.allSettled(interestsOnPopularPromises);

                // Updating topics with isbns
                topics = topicsOfInterest.map((t, i) => {
                    if (t.status === 'fulfilled' && t.value.data.isbns.length > 0) {
                        return {
                            ...topics[i],
                            isbns: t?.value?.data?.isbns,
                            status: 'gotIsbns',
                        };
                    }

                    return {
                        ...topics[i],
                        status: 'empty',
                    };
                });

                // Array of promises for book lists on each interest
                const topicsOfInterestBooksPromises = topics
                    .map((t) => t.isbns)
                    .map((i) => getBookList(request, i, false));

                // Array of resolved promises for book lists on each interest
                const topicsOfInterestBooks = await Promise.allSettled(topicsOfInterestBooksPromises);

                // Updating topics with books
                topics = topicsOfInterestBooks.map((t, i) => {
                    if (t.status === 'fulfilled' && topics[i].status === 'gotIsbns' && t.value.data.total > 0) {
                        return {
                            ...topics[i],
                            books: t.value.data.items,
                            status: 'gotBooks',
                        };
                    }

                    return {
                        ...topics[i],
                        status: 'empty',
                    };
                });
            }
        }

        //
        // Create carousels
        //

        const topicCarousels = topics
            .filter((topic) => topic.status === 'gotBooks')
            .map((topic) => {
                let filteredBooks = topic.books;
                if (user.organisation.isOrganisationUser) {
                    filteredBooks = filteredBooks.filter(
                        (book) => book.meta.availableInSubscription || book.meta.freeBook,
                    );
                }
                return {
                    key: topic.key,
                    title: topic.label,
                    books: filteredBooks,
                    to: `/fagomrade/${topic.routeId}`,
                };
            })
            .filter((topic) => topic.books.length > 0);

        const cmsCarousels = bookChunks.map((chunk: any, i: number) => {
            const bookListPromise = bookLists[i];

            let bookList: Array<BookTypes>;
            if (bookListPromise && bookListPromise.status === 'fulfilled') {
                if (user.organisation.isOrganisationUser) {
                    bookList = bookListPromise.value.data.items.filter(
                        (book: BookTypes) => book.meta.availableInSubscription || book.meta.freeBook,
                    );
                } else {
                    bookList = bookListPromise?.value?.data?.items;
                }
            } else {
                bookList = [];
            }

            return {
                key: resolveFieldFromChunk(chunk, 'title', 'text'),
                title: resolveFieldFromChunk(chunk, 'title', 'text'),
                books: bookList,
                to: '',
            };
        });

        const books = [...topicCarousels, ...cmsCarousels];

        //
        // Prepare user data and inventory

        if (userSubs.status === 'fulfilled' && (userSubs.value.status === 200 || userSubs.value.status === 201)) {
            plans = userSubs.value.data.items;
        }

        if (
            booksInSubsResult.status === 'fulfilled' &&
            (booksInSubsResult.value.status === 200 || booksInSubsResult.value.status === 201)
        ) {
            booksInSubs = booksInSubsResult.value.data.items;
        }

        const lastReadBooks =
            lastReadInventory.status === 'fulfilled'
                ? {
                      items: lastReadInventory.value.data.items.map((b) => b.book),
                      total: lastReadInventory.value.data.total,
                      size: lastReadInventory.value.data.size,
                  }
                : {
                      items: [],
                      total: 0,
                      size: 0,
                  };

        const lastAcquiredBooks =
            lastAcquiredInventory.status === 'fulfilled'
                ? {
                      items: lastAcquiredInventory.value.data.items.map((b) => b.book),
                      total: lastAcquiredInventory.value.data.total,
                      size: lastAcquiredInventory.value.data.size,
                  }
                : {
                      items: [],
                      total: 0,
                      size: 0,
                  };

        let recruitedUsersArray: RecruitedUsersType = [];
        if (recruitedUsers.status === 'fulfilled' && recruitedUsers.value) {
            if (!recruitedUsers.value.errorResponse && Array.isArray(recruitedUsers.value.data)) {
                recruitedUsersArray = recruitedUsers.value.data?.filter((el) => !el.providerRewardClaimed);
            } else {
                // @TODO: Log error to datadog
            }
        }
        return json({
            lineBanner,
            lastReadBooks,
            lastAcquiredBooks,
            books,
            blogPosts,
            plans,
            booksInSubs,
            isOrgUser: user.organisation.isOrganisationUser,
            recruitedUsers: recruitedUsersArray,
        });
    }

    // Data retrieval for frontpage for signed out users
    const data = await fetchFrontpage();

    const variantArray = [
        TestimonialVariant.SecondaryTransparent,
        TestimonialVariant.SecondaryTransparent,
        TestimonialVariant.SecondaryDark,
        TestimonialVariant.Primary,
        TestimonialVariant.SecondaryTransparent,
    ];

    const formTestimonials = (testimonials: Array<CrystallizeApiTestimonial>) => {
        const testimonialsToReturn: Array<TestimonialTypes> = [];
        testimonials.map((testimonial: CrystallizeApiTestimonial, index: number) => {
            const randomNumber = Math.floor(Math.random() * 5);

            const { node } = testimonial;
            const { content } = node?.content || {};
            const jsonContent = content?.json;
            const contentParagraphs: Array<string> = [];

            if (jsonContent) {
                jsonContent.map((paragraph: any) => {
                    const firstChild = paragraph.children;

                    let textContent = null;
                    if (paragraph.textContent) {
                        textContent = paragraph.textContent;
                    } else if (firstChild && firstChild.length > 0) {
                        textContent = firstChild[0].textContent;
                    }

                    if (textContent) {
                        contentParagraphs.push(textContent);
                    }
                });
            }

            const item: TestimonialTypes = {
                key: node?.id,
                headline: node?.headline?.content?.text || null,
                contentParagraphs,
                name: node?.name?.content?.text || null,
                title: node?.title?.content?.text || null,
                createdAt: node?.createdAt || 0,
            };

            if (randomNumber > 2 || index + variantArray.length === testimonials.length) {
                if (variantArray.length) {
                    const randomIndex = Math.floor(Math.random() * (variantArray.length - 1));
                    item.variant = variantArray[randomIndex];
                    variantArray.splice(randomIndex, 1);
                }
            }
            testimonialsToReturn.push(item);
        });
        return testimonialsToReturn;
    };

    const testimonialsFromCristalyze = resolveEdges(data, 'testimonials');
    const testimonials = formTestimonials(testimonialsFromCristalyze);

    const books = await useCacheForFunction('frontpage:books', async () => {
        const bookChunks = resolveChunks(data, ['frontpage', 'books']);

        const booksPromises = bookChunks.map((chunk: any) => {
            const isbns = resolveFieldFromChunk(chunk, 'isbns', 'text');
            const isbnsSplit = isbns.replace(/\s/g, '').split(',');
            return getBookList(request, isbnsSplit, false);
        });

        //
        // Resolve promises
        //

        const bookLists = await Promise.allSettled(booksPromises);

        const books = bookChunks.map((chunk: any, i: number) => {
            const bookList = bookLists[i];

            return {
                preTitle: resolveFieldFromChunk(chunk, 'pre-title', 'text'),
                title: resolveFieldFromChunk(chunk, 'title', 'text'),
                paragraph: resolveFieldFromChunk(chunk, 'paragraph', 'text'),
                books: bookList && bookList.status === 'fulfilled' ? bookList.value.data?.items : [],
            };
        });
        return books;
    });

    const frontpage = {
        fold: {
            preTitle: resolveField(data, ['frontpage', 'fold'], 'pre-title', 'text'),
            title: resolveField(data, ['frontpage', 'fold'], 'title', 'text'),
            illustration: resolveField(data, ['frontpage', 'fold'], 'illustration', 'firstImage'),
            paragraph: resolveField(data, ['frontpage', 'fold'], 'paragraph', 'json'),
        },
        howItWorks: {
            preTitle: resolveField(data, ['frontpage', 'howItWorks'], 'pre-title', 'text'),
            title: resolveField(data, ['frontpage', 'howItWorks'], 'title', 'text'),
            steps: resolveField(data, ['frontpage', 'howItWorks'], 'steps', 'paragraphs'),
        },
        books,
        themas: {
            title: resolveField(data, ['frontpage', 'themas'], 'title', 'text'),
            paragraph: resolveField(data, ['frontpage', 'themas'], 'paragraph', 'json'),
        },
        blog: {
            title: resolveField(data, ['frontpage', 'blog'], 'title', 'text'),
            paragraph: resolveField(data, ['frontpage', 'blog'], 'paragraph', 'json'),
            blogposts: resolveEdges(data, 'lastBlogposts')
                ?.filter((post: any) => !post?.node?.html)
                .slice(0, 3),
        },
        banner: {
            preTitle: resolveField(data, ['frontpage', 'banner'], 'pre-title', 'text'),
            title: resolveField(data, ['frontpage', 'banner'], 'title', 'text'),
            illustration: resolveField(data, ['frontpage', 'banner'], 'illustration', 'firstImage'),
            paragraph: resolveField(data, ['frontpage', 'banner'], 'paragraph', 'json'),
        },
        lineBanner: {
            label: resolveField(data, ['frontpage', 'lineBanner'], 'label', 'text'),
            url: resolveField(data, ['frontpage', 'lineBanner'], 'url', 'text'),
        },
        testimonials: testimonials,
    };

    return json({ frontpage, isOrgUser: false });
};

export function meta({ matches }: { matches: any }): Array<{ [key: string]: string }> {
    const rootMeta = matches[0].meta;
    return [...rootMeta.filter((el: any) => !el.title), { title: 'Digitale pensumbøker på nett • Allvit' }];
}

const IndexPage = () => {
    const currentUser = useCurrentUser();

    if (currentUser.signedIn) {
        return <DashboardPage />;
    }
    return <FrontPage />;
};

export default IndexPage;
