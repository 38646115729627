export const lazyloadBackground = ({
    elementId,
    intersectionHandler,
    once = true,
}: {
    elementId: string;
    intersectionHandler: (entry: IntersectionObserverEntry) => void;
    once: boolean;
}) => {
    if (typeof window === 'undefined') return;

    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.map((entry) => {
                if (entry.isIntersecting) {
                    intersectionHandler(entry);
                    if (once) {
                        observer.unobserve(entry.target);
                    }
                }
            });
        },
        { rootMargin: '200px' },
    );

    const image = document.getElementById(elementId);
    if (image && observer) {
        observer.observe(image);
    }
};
