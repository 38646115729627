import { Box, Stack, SxProps, Theme, Typography, alpha, useTheme } from '@mui/material';

import { TestimonialTypes } from '~/types/app/testimonial';
import { mergeSx } from '~/utils/mui';

const Testimonial = ({ testimonial, sx }: { testimonial: TestimonialTypes; sx?: SxProps<Theme> }) => {
    const theme = useTheme();
    const { contentParagraphs, variant, name, title, headline } = testimonial;

    const numWords = contentParagraphs.join(' ').split(' ').length;

    let backgroundColor = theme.palette.background.dark;
    let color = theme.palette.text.primary;
    let fontSize = 'inherit';
    let fontWeight = 'inherit';
    let lineHeight = 'inherit';
    let headlineFontSize = '1.3em';

    if (variant && variant === 'secondaryDark') {
        backgroundColor = theme.palette.secondary.main;
        color = theme.palette.common.white;
    }

    if (variant && variant === 'secondaryTransparent') {
        backgroundColor = alpha(theme.palette.secondary.main, 0.1);
    }

    if (variant && variant === 'primary') {
        backgroundColor = theme.palette.primary.main;
    }

    if (numWords < 29) {
        fontSize = '1.6em';
        headlineFontSize = '1.8em';
        fontWeight = '700';
        lineHeight = '1.25';
    } else if (numWords < 60) {
        fontSize = '1.3em';
        headlineFontSize = '1.5em';
        fontWeight = '600';
        lineHeight = '1.3';
    }

    return (
        <Stack
            sx={mergeSx(
                {
                    p: { xs: 6, lg: 8 },
                    borderRadius: theme.radius.allLarge,
                    backgroundColor,
                },
                sx,
            )}
        >
            <Box
                sx={{
                    height: { xs: '300px', sm: '450px', md: '350px', xl: '300px', xxl: '240px' },
                    overflow: 'hidden',
                }}
            >
                {headline && (
                    <Typography sx={{ color, mb: 4, fontSize: headlineFontSize, lineHeight, fontWeight: 600 }}>
                        {headline}
                    </Typography>
                )}
                {contentParagraphs?.map((text, index) => (
                    <Typography
                        key={`${testimonial.key}-${index}`}
                        sx={{ color, fontSize, lineHeight, fontWeight }}
                        variant="p1"
                    >
                        {text}
                    </Typography>
                ))}
            </Box>
            <Stack sx={{ height: '50px', overflow: 'hidden' }}>
                <Typography sx={{ color, fontWeight: '600', mb: 1 }} variant="p2">
                    {name || ''}
                </Typography>
                <Typography variant="p3" sx={{ color, overflow: 'hidden' }}>
                    {title}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default Testimonial;
