import { Box, Stack, Typography, alpha, useTheme } from '@mui/material';
import { ChangeEvent, useRef } from 'react';

import { ContentTransformer } from '@crystallize/reactjs-components';
import { Link, useLoaderData } from '@remix-run/react';

import { useCurrentUser } from '~/utils/user';

import Button from '~/components/atoms/Button';
import Heading from '~/components/atoms/Heading';

import BlogTile from '~/components/molecules/BlogTile';
import FrontContainer from '~/components/molecules/FrontContainer';
import TopBannerButton from '~/components/molecules/TopBannerButton';

import BookCarousel from '~/components/organisms/BookCarousel';
import Topics from '~/components/organisms/Topics';

import OutsideLayout from '~/components/templates/Outside';
import Header from '~/components/templates/Outside/components/Header';

import { useMemo, useState } from 'react';
import AllvitPlussBannerFloor from '~/components/molecules/AllvitPlussBannerFloor';
import AnimatedTitle from '~/components/molecules/AnimatedTitle';
import SearchInput from '~/components/molecules/SearchInput';
import TestimonialCarousel from '~/components/organisms/TestimonialCarousel';
import globalConfig from '~/configs/global';
import BookTypes from '~/types/app/book';
import { TestimonialTypes } from '~/types/app/testimonial';

export default function FrontPage() {
    const theme = useTheme();
    const currentUser = useCurrentUser();
    const { frontpage } = useLoaderData() as any;
    const testimonials = (frontpage?.testimonials || []) as Array<TestimonialTypes>;
    const sortedTestimonials = testimonials?.sort((a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1));

    const [searchInputValue, setSearchInputValue] = useState('');
    const searchFormRef = useRef<HTMLFormElement | null>(null);

    const visibleTestimonials = sortedTestimonials.slice(0, 12);

    const lineBanner = frontpage?.lineBanner;
    const showLineBanner = lineBanner && lineBanner.label && lineBanner.url;

    const howItWorksSteps = frontpage?.howItWorks?.steps;

    const getRandomBook = (bookChunks: any): BookTypes | null => {
        if (!bookChunks) {
            return null;
        }

        const allvitPlussBooks = bookChunks.reduce((a: Array<BookTypes>, c: any) => {
            if (c.books) {
                return [...a, ...c.books.filter((book: BookTypes) => book.meta.availableInSubscription)];
            }

            return a;
        }, []);

        if (allvitPlussBooks.length > 0) {
            const randomIndex = Math.floor(Math.random() * allvitPlussBooks.length);

            return allvitPlussBooks[randomIndex];
        }

        return null;
    };

    const randomAllvitPlussBook = useMemo(() => getRandomBook(frontpage?.books), []);

    return (
        <OutsideLayout hideHeader signedIn={currentUser.signedIn}>
            {showLineBanner && <TopBannerButton label={lineBanner.label} to={lineBanner.url} />}
            <Stack>
                <Header signedIn={false} />
                <Stack
                    sx={{
                        px: 4,
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5" sx={{ mb: 4, fontWeight: '700', textAlign: 'center' }}>
                        {frontpage?.fold.preTitle}
                    </Typography>
                    <Heading
                        component="h1"
                        variant="h0"
                        sx={{
                            mb: { xs: 8, md: 12 },
                            textAlign: 'center',
                            fontSize: { xs: '36px', md: '48px', xl: '58px' },
                        }}
                    >
                        <AnimatedTitle
                            title={frontpage?.fold.title.split('|')[0]}
                            word={frontpage?.fold.title.split('|')[1]}
                            highlightColor={theme.palette.primary.main}
                            selectColor={alpha(theme.palette.cta.main, 0.15)}
                        />
                    </Heading>
                </Stack>
                <Stack
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    <FrontContainer maxWidth="sm" sx={{ mb: { xs: 8, md: 12 } }}>
                        <form ref={searchFormRef} action="/sok/boker" method="get">
                            <SearchInput
                                size="medium"
                                inputState={'idle'}
                                onEnter={() => {
                                    if (searchFormRef && searchFormRef.current) {
                                        searchFormRef.current.submit();
                                    }
                                }}
                                value={searchInputValue}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setSearchInputValue(e.target.value);
                                }}
                                inputProps={{
                                    name: 'q',
                                    placeholder: 'Søk blant over 4000 bøker',
                                }}
                            />
                        </form>
                    </FrontContainer>
                    {howItWorksSteps && (
                        <FrontContainer maxWidth="xxl" sx={{ mb: 24 }}>
                            <Stack sx={{ width: '100%', flexDirection: { xs: 'column', lg: 'row' } }}>
                                {howItWorksSteps[0] ? (
                                    <Stack
                                        sx={{
                                            mr: { xs: 0, lg: 2 },
                                            mb: { xs: 2, sm: 4, lg: 0 },
                                            width: { xs: 1, lg: 1 / 3 },
                                            flexDirection: { xs: 'column', sm: 'row', lg: 'column' },
                                        }}
                                    >
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                order: { xs: 1, sm: 0 },
                                                mb: { xs: 0, lg: 4 },
                                                mr: { xs: 0, sm: 4, lg: 0 },
                                                width: { xs: '100%', sm: 4 / 10, lg: '100%' },
                                                height: { xs: '120px', sm: '190px', lg: '200px' },
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                                borderRadius: theme.radius.allLarge,
                                                backgroundImage: `url(${howItWorksSteps[0]?.images?.[0].url})`,
                                            }}
                                        />

                                        <Stack
                                            sx={{
                                                order: { xs: 0, sm: 1 },
                                                px: { xs: 4, md: 6, xl: 8 },
                                                pt: { xs: 4, md: 6, xl: 8 },
                                                pb: { xs: 2, md: 4, xl: 6 },
                                                mb: { xs: 2, sm: 0 },
                                                width: { xs: '100%', sm: 6 / 10, lg: '100%' },
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: theme.radius.allLarge,
                                                flexGrow: '1',
                                            }}
                                        >
                                            <Typography variant="p1" sx={{ fontWeight: '700' }}>
                                                {howItWorksSteps[0]?.title?.text}
                                            </Typography>
                                            <ContentTransformer json={howItWorksSteps[0]?.body?.json} />
                                        </Stack>
                                    </Stack>
                                ) : (
                                    <Stack
                                        sx={{
                                            mr: { xs: 0, lg: 2 },
                                            mb: { xs: 4, lg: 0 },
                                        }}
                                    />
                                )}
                                {howItWorksSteps[1] ? (
                                    <Stack
                                        sx={{
                                            mx: { xs: 0, lg: 2 },
                                            mb: { xs: 2, sm: 4, lg: 0 },
                                            width: { xs: 1, lg: 1 / 3 },
                                            flexDirection: { xs: 'column', sm: 'row', lg: 'column' },
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                px: { xs: 4, md: 6, xl: 8 },
                                                pt: { xs: 4, md: 6, xl: 8 },
                                                pb: { xs: 2, md: 4, xl: 6 },
                                                mb: { xs: 2, sm: 0, lg: 4 },
                                                mr: { xs: 0, sm: 4, lg: 0 },
                                                width: { xs: '100%', sm: 6 / 10, lg: '100%' },
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: theme.radius.allLarge,
                                            }}
                                        >
                                            <Typography variant="p1" sx={{ fontWeight: '700' }}>
                                                {howItWorksSteps[1]?.title?.text}
                                            </Typography>
                                            <ContentTransformer json={howItWorksSteps[1]?.body?.json} />
                                        </Stack>
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                backgroundImage: `url(${howItWorksSteps[1]?.images?.[0].url})`,
                                                width: { xs: '100%', sm: 4 / 10, lg: '100%' },
                                                height: { xs: '120px', sm: '190px', lg: '200px' },
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                                borderRadius: theme.radius.allLarge,
                                            }}
                                        />
                                    </Stack>
                                ) : (
                                    <Stack
                                        sx={{
                                            mr: { xs: 0, lg: 2 },
                                            mb: { xs: 4, lg: 0 },
                                            width: { xs: 1, lg: 1 / 3 },
                                        }}
                                    />
                                )}

                                {howItWorksSteps[2] ? (
                                    <Stack
                                        sx={{
                                            mr: { xs: 0, lg: 2 },
                                            mb: { xs: 4, lg: 0 },
                                            width: { xs: 1, lg: 1 / 3 },
                                            flexDirection: { xs: 'column', sm: 'row', lg: 'column' },
                                        }}
                                    >
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                order: { xs: 1, sm: 0 },
                                                mb: { xs: 2, sm: 0, lg: 4 },
                                                mr: { xs: 0, sm: 4, lg: 0 },
                                                width: { xs: '100%', sm: 4 / 10, lg: '100%' },
                                                height: { xs: '120px', sm: '190px', lg: '200px' },
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center center',
                                                borderRadius: theme.radius.allLarge,
                                                backgroundImage: `url(${howItWorksSteps[2]?.images?.[0].url})`,
                                            }}
                                        />

                                        <Stack
                                            sx={{
                                                order: { xs: 0, sm: 1 },
                                                px: { xs: 4, md: 6, xl: 8 },
                                                pt: { xs: 4, md: 6, xl: 8 },
                                                pb: { xs: 2, md: 4, xl: 6 },
                                                mb: { xs: 2, sm: 0 },
                                                width: { xs: '100%', sm: 6 / 10, lg: '100%' },
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: theme.radius.allLarge,
                                                flexGrow: '1',
                                            }}
                                        >
                                            <Typography variant="p1" sx={{ fontWeight: '700' }}>
                                                {howItWorksSteps[2]?.title?.text}
                                            </Typography>
                                            <ContentTransformer json={howItWorksSteps[2]?.body?.json} />
                                        </Stack>
                                    </Stack>
                                ) : (
                                    <Stack
                                        sx={{
                                            mr: { xs: 0, lg: 2 },
                                            mb: { xs: 4, lg: 0 },
                                        }}
                                    />
                                )}
                            </Stack>
                        </FrontContainer>
                    )}
                </Stack>
            </Stack>
            <Stack sx={{ mb: 36, backgroundColor: theme.palette.background.dark }}>
                <FrontContainer maxWidth="xxl" sx={{ py: 22, alignItems: 'center' }}>
                    <Heading variant="h2" sx={{ mb: 12, maxWidth: '680px', textAlign: 'center' }}>
                        Utforsk fagområder
                    </Heading>
                    <Topics center showEmojis size="large" topics={globalConfig.topics} excludeTopics={['Annet']} />
                </FrontContainer>
            </Stack>

            <AllvitPlussBannerFloor compareBook={randomAllvitPlussBook} />

            <FrontContainer maxWidth="xxl" sx={{ mb: 36, alignItems: 'center' }}>
                <Heading variant="h2" sx={{ mb: 12, maxWidth: '680px', textAlign: 'center' }}>
                    Dette sier studentene
                </Heading>
                <TestimonialCarousel id="testimonials" testimonials={visibleTestimonials} />
            </FrontContainer>

            <Stack>
                {frontpage?.books?.map((bookChunk: any) => {
                    const key = bookChunk && bookChunk.books && bookChunk.books[0] && bookChunk.books[0].id;

                    return (
                        <Box
                            key={key}
                            sx={{
                                mb: 36,
                            }}
                        >
                            <FrontContainer maxWidth="xxl">
                                <Typography variant="p2" sx={{ textAlign: 'center', mb: 2 }}>
                                    {bookChunk.preTitle}
                                </Typography>
                                <Heading component="h2" variant="h2" sx={{ textAlign: 'center', mb: 2 }}>
                                    {bookChunk.title}
                                </Heading>
                                <BookCarousel
                                    maxWidth="xxl"
                                    id={key}
                                    books={bookChunk.books}
                                    showPricingInformation={
                                        bookChunk.books?.filter(
                                            (book: BookTypes) => book.meta.lowestAvailablePrice !== 0,
                                        ).length > 0 // free books carousel should not show pricing information
                                    }
                                />
                            </FrontContainer>
                        </Box>
                    );
                })}
            </Stack>

            <FrontContainer maxWidth="xxl" sx={{ alignItems: 'center', mb: 36 }}>
                <Heading variant="h2" sx={{ textAlign: 'center', maxWidth: '680px' }}>
                    {frontpage?.blog.title}
                </Heading>
                <Typography component="div" variant="p1" sx={{ mb: 12, textAlign: 'center', maxWidth: '680px' }}>
                    <ContentTransformer json={frontpage?.blog.paragraph} />
                </Typography>
                <Stack
                    sx={{
                        mb: 12,
                        display: 'grid',
                        alignItems: 'start',
                        gridTemplateColumns: { lg: '1fr 1fr 1fr', md: '1fr 1fr ', xs: '1fr' },
                        gridGap: '1.5rem',
                    }}
                >
                    {frontpage?.blog.blogposts?.map((blog: any) => {
                        if (!blog) return null;
                        return <BlogTile hideLeadin blog={blog.node} key={blog.node.id} titleComponent="h3" />;
                    })}
                </Stack>
                <Stack sx={{ alignItems: 'center' }}>
                    <Button component={Link} to="/blogg" variant="secondary" size="large">
                        Vis alle innlegg
                    </Button>
                </Stack>
            </FrontContainer>
        </OutsideLayout>
    );
}
