import { Stack, StackProps, Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Link } from '@remix-run/react';
import { ReactElement } from 'react';

import Heading from '~/components/atoms/Heading';
import BookCarousel from '~/components/organisms/BookCarousel';

import BookTile from '~/components/molecules/BookTile';
import BookTypes from '~/types/app/book';

interface BookRowProps extends StackProps {
    icon?: ReactElement;
    moreButton?: ReactElement | null;
    books: Array<BookTypes>;
    heading: string;
    moreLabel?: string;
    moreTo?: string;
    sx?: SxProps<Theme>;
    showPricingInformation: boolean;
}

const BookRow = ({ books, icon, heading, moreButton, showPricingInformation, sx, ...props }: BookRowProps) => {
    const theme = useTheme();

    return (
        <Stack {...props} sx={sx}>
            <Stack
                sx={{
                    mb: 2,
                    pb: 2,
                    borderBottom: theme.border.medium,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                    {icon}
                    <Heading
                        component="h2"
                        variant="h6"
                        sx={{
                            ml: !icon ? 0 : 3,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            minHeight: '40px',
                        }}
                    >
                        {heading}
                    </Heading>
                </Stack>
                {moreButton}
            </Stack>
            {books.length < 3 ? (
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}
                >
                    {books.map((book) => (
                        <BookTile
                            key={book.id}
                            hideProgress
                            hideHighlights
                            book={book}
                            component={Link}
                            to={book.meta.url}
                            sx={{ width: `47%`, maxWidth: '219px', mr: 7 }}
                            showPricingInformation={showPricingInformation}
                        />
                    ))}
                </Stack>
            ) : (
                <BookCarousel
                    id={`carousel-${heading}`}
                    maxWidth="xxl"
                    books={books}
                    showPricingInformation={showPricingInformation}
                />
            )}
        </Stack>
    );
};

export default BookRow;
