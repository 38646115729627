import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';

import { Link } from '@remix-run/react';

import Button from '~/components/atoms/Button';
import Heading from '~/components/atoms/Heading';

// @ts-ignore
import { ClientOnly } from 'remix-utils/client-only';

import { useEffect, useState } from 'react';
import { imageUrls } from '~/assets';
import BookTypes from '~/types/app/book';
import FrontContainer from '../FrontContainer';
import { lazyloadBackground } from '~/utils/performance';
import { getBookImageUrl } from '~/utils/book';

interface AllvitPlussBannerFloorProps extends BoxProps {
    compareBook: BookTypes | null;
}

const AllvitPlussBannerFloor = ({ compareBook }: AllvitPlussBannerFloorProps): JSX.Element | null => {
    const theme = useTheme();

    if (!compareBook) {
        return null;
    }

    const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>('');

    useEffect(() => {
        lazyloadBackground({
            elementId: 'allvit-pluss-banner-floor-book-grid',
            // backgroundImageUrl: imageUrls.bookGrid,
            intersectionHandler: () => {
                setBackgroundImageUrl(imageUrls.bookGrid);
                // if ((entry.target as HTMLElement).style) {
                //     (entry.target as HTMLElement).style.backgroundImage = `url(${imageUrls.bookGrid})`;
                // }
            },
            once: true,
        });
    }, []);

    return (
        <Stack sx={{ mb: 36 }}>
            <FrontContainer maxWidth="xl">
                <Box
                    sx={{
                        py: { xs: 8, sm: 12 },
                        width: '100%',
                        backgroundColor: theme.palette.primary.main,
                        backgroundImage: `url(${imageUrls.allvitBannerBackground})`,
                        backgroundRepeat: 'repeat-y',
                        backgroundPosition: 'center',
                        borderRadius: theme.radius.allXLarge,
                    }}
                >
                    <Heading variant="h2" sx={{ mb: { xs: 8, sm: 12 }, px: { xs: 6, md: 8 }, textAlign: 'center' }}>
                        Abonnementet Allvit+ lønner seg
                    </Heading>
                    <Stack sx={{ flexDirection: 'row', mb: { xs: 10, sm: 12 } }}>
                        <ClientOnly fallback={<Stack sx={{ width: 1 / 2 }} />}>
                            {() => (
                                <Stack sx={{ width: 1 / 2, px: 4 }}>
                                    <Typography
                                        variant="p1"
                                        sx={{
                                            mb: 1,
                                            fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {`Denne boka koster ${compareBook.meta.grossSalePrice},-`} digitalt
                                    </Typography>
                                    <Typography
                                        variant="p2"
                                        sx={{
                                            fontSize: { xs: '14px', sm: '16px', md: '18px' },
                                            textAlign: 'center',
                                        }}
                                    >
                                        &nbsp;
                                    </Typography>
                                </Stack>
                            )}
                        </ClientOnly>
                        <Stack sx={{ width: 1 / 2, px: 4 }}>
                            <Typography
                                variant="p1"
                                sx={{
                                    mb: 1,
                                    fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                Allvit+ koster 206,- per mnd*
                            </Typography>
                            <Typography
                                variant="p2"
                                sx={{
                                    fontSize: { xs: '14px', sm: '16px', md: '18px' },
                                    textAlign: 'center',
                                }}
                            >
                                Da får du tilgang til omtrent 2000 bøker i tillegg
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack sx={{ position: 'relative', width: '100%', flexDirection: 'row', mb: { xs: 10, sm: 12 } }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                bottom: '0',
                                height: { xs: '140px', sm: '180px', md: '200px' },
                                width: '35%',
                                backgroundImage: `linear-gradient(to right, transparent, ${theme.palette.primary.main});`,
                            }}
                        />
                        <Stack sx={{ width: 1 / 2 }}>
                            <Stack
                                sx={{
                                    width: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                <ClientOnly
                                    fallback={<Box sx={{ height: { xs: '140px', sm: '180px', md: '200px' } }} />}
                                >
                                    {() => (
                                        <Box
                                            component="img"
                                            loading="lazy"
                                            onError={({ currentTarget }) => {
                                                currentTarget.src = getBookImageUrl(compareBook, true);
                                            }}
                                            src={compareBook.imageUrl}
                                            sx={{
                                                borderRadius: theme.radius.all,
                                                boxShadow: theme.shadows[3],
                                                height: { xs: '140px', sm: '180px', md: '200px' },
                                            }}
                                        />
                                    )}
                                </ClientOnly>
                            </Stack>
                        </Stack>
                        <Stack
                            sx={{
                                width: 1 / 2,
                                pl: { xs: '20px', md: '40px' },
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <ClientOnly fallback={<Box sx={{ height: { xs: '140px', sm: '180px', md: '200px' } }} />}>
                                {() => (
                                    <Box
                                        component="img"
                                        loading="lazy"
                                        onError={({ currentTarget }) => {
                                            currentTarget.src = getBookImageUrl(compareBook, true);
                                        }}
                                        src={compareBook.imageUrl}
                                        sx={{
                                            borderRadius: theme.radius.all,
                                            boxShadow: theme.shadows[3],
                                            height: { xs: '70px', sm: '80px', md: '100px' },
                                        }}
                                    />
                                )}
                            </ClientOnly>
                            <Typography sx={{ mx: { xs: 2, sm: 4 }, fontSize: '50px' }}>+</Typography>
                            <Box
                                id="allvit-pluss-banner-floor-book-grid"
                                sx={{
                                    height: { xs: '140px', sm: '180px', md: '200px' },
                                    width: '100%',
                                    backgroundImage: `url(${backgroundImageUrl})`, // Is being lazy loaded
                                    backgroundRepeat: 'repeat-x',
                                    backgroundPosition: 'left center',
                                    backgroundSize: 'auto 100%',
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        sx={{
                            mb: 6,
                            px: { xs: 6, md: 8 },
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            component={Link}
                            to="/start-allvit-pluss"
                            variant="primary"
                            overrideColor={theme.palette.text.primary}
                            sx={{
                                width: { xs: '100%', sm: '200px' },
                                mr: { xs: 0, sm: 6 },
                                mb: { xs: 4, sm: 0 },
                                justifyContent: 'center',
                            }}
                            size="large"
                        >
                            Start Allvit+ nå!
                        </Button>
                        <Button
                            component={Link}
                            to="/allvit-pluss"
                            overrideColor={theme.palette.text.primary}
                            variant="secondary"
                            size="large"
                            sx={{
                                width: { xs: '100%', sm: '200px' },
                                justifyContent: 'center',
                            }}
                        >
                            Les mer om Allvit+
                        </Button>
                    </Stack>
                    <Stack
                        sx={{
                            px: { xs: 6, md: 8 },
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="p3" sx={{ textAlign: 'center' }}>
                            * Prisen blir i gjennomsnitt kr. 206 pr. mnd dersom du bruker Allvit+ i ett år
                        </Typography>
                    </Stack>
                </Box>
            </FrontContainer>
        </Stack>
    );
};

export default AllvitPlussBannerFloor;
